import { type VariantProps, cva } from 'class-variance-authority';

const badge = cva(
  [
    'inline-block',
    'relative',
    'px-1.5',
    'py-1',
    'leading-none',
    'rounded',
    'bg-white/80',
    'backdrop-blur-[6px]',
    'lowercase',
  ],
  {
    variants: {
      size: {
        sm: ['text-xs'],
        md: ['text-[13px]'],
        lg: ['text-base'],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export function TicketBadge({
  title,
  size,
}: VariantProps<typeof badge> & { title: string }) {
  return <span className={badge({ size })}>{title}</span>;
}
