'use client';

import {
  type MaterialCollection,
  getMaterialMainImage,
} from '@afishauz/api/materials';
import type { Section } from '@afishauz/api/sections';
import { useNewsAndReviews } from '@afishauz/core/pages/shared';
import { useLocale } from '@afishauz/core/utils/i18n';
import { Card } from '@afishauz/core/widgets/card';
import { CardsGrid } from '@afishauz/core/widgets/cards-grid';
import { LoadingButton } from '@afishauz/core/widgets/loading-button';
import { PublishedAt } from '@afishauz/core/widgets/published-at';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { VideoBadge } from '@afishauz/core/widgets/video-badge';
import { Box } from '@afishauz/ui-kit/box';
import { CardMeta, CardTitle } from '@afishauz/ui-kit/card';
import { Container } from '@afishauz/ui-kit/container';
import { useTranslations } from 'next-intl';

interface Props {
  initialData: MaterialCollection;
  section?: Section;
  isOnHomepage?: boolean;
}

export const NewsAndReviews = ({
  section,
  isOnHomepage,
  initialData,
}: Props) => {
  const t = useTranslations('common');
  const locale = useLocale();
  const { list, isValidating, getNextPage, hasMoreItems } = useNewsAndReviews({
    section,
    isOnHomepage,
    initialData,
    locale,
  });

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle>{t('templates.news_and_reviews')}</SectionTitle>
        <CardsGrid>
          {list.map(material => (
            <Card
              link={{
                href: material.permalinkCanonical,
                native: material.tildaSubstitute,
              }}
              img={{
                src: getMaterialMainImage(material, 'medium'),
                alt: material.title,
              }}
              size='lg'
              badges={[
                {
                  component: material.type === 'VIDEO' && (
                    <VideoBadge size='md' />
                  ),
                },
              ]}
              key={material['@id']}
            >
              <CardTitle title={material.title}>{material.title}</CardTitle>
              <CardMeta>
                <PublishedAt date={new Date(material.publishedAt)} />
              </CardMeta>
            </Card>
          ))}
        </CardsGrid>
        {hasMoreItems && (
          <LoadingButton
            loading={isValidating}
            className='mt-6'
            onClick={getNextPage}
            disabled={isValidating}
            size='lg'
          />
        )}
      </Container>
    </Box>
  );
};
