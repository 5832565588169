import(/* webpackMode: "eager", webpackExports: ["ImageWithFallback"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/image-with-fallback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cinema"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/home/cinema.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Expected"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/home/expected.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AsideBanners"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/shared/aside-banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalBanner"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/shared/horizontal-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsAndReviews"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/shared/news-and-reviews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.19.1_next@14.2.8_@playwright+test@1.43.1_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.19.1_next@14.2.8_@playwright+test@1.43.1_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/packages/api/src/materials/hooks.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/packages/api/src/use-collection.ts");
