import {
  Card as AfishaUzCard,
  CardBadge,
  CardImage,
} from '@afishauz/ui-kit/card';
import { type VariantProps, cva, cx } from 'class-variance-authority';
import type { PropsWithChildren, ReactNode } from 'react';
import { ImageWithFallback } from './image-with-fallback';
import { Link } from './link';

const card = cva([], {
  variants: {
    size: {
      sm: [],
      md: [],
      lg: [],
      fluid: ['w-full'],
    },
    orientation: {
      portrait: [],
      landscape: [],
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      orientation: 'landscape',
      class: ['w-72'],
    },
    {
      size: 'md',
      orientation: 'landscape',
      class: ['w-[312px]'],
    },
    {
      size: 'lg',
      orientation: 'landscape',
      class: ['w-96'],
    },
    {
      size: 'sm',
      orientation: 'portrait',
      class: ['w-[130px]'],
    },
    {
      size: 'md',
      orientation: 'portrait',
      class: ['w-[180px]'],
    },
    {
      size: 'lg',
      orientation: 'portrait',
      class: ['w-72'],
    },
  ],
  defaultVariants: {
    size: 'md',
    orientation: 'landscape',
  },
});

const image = cva([], {
  variants: {
    size: {
      sm: [],
      md: [],
      lg: [],
      fluid: ['w-full'],
    },
    orientation: {
      portrait: [],
      landscape: [],
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      orientation: 'landscape',
      class: ['w-72', 'h-48', 'min-w-[288px]', 'min-h-[192px]'],
    },
    {
      size: 'md',
      orientation: 'landscape',
      class: ['w-[312px]', 'h-[208px]', 'min-w-[312px]', 'min-h-[208px]'],
    },
    {
      size: 'lg',
      orientation: 'landscape',
      class: ['w-96', 'h-64', 'min-w-[384px]', 'min-h-[256px]'],
    },
    {
      size: 'sm',
      orientation: 'portrait',
      class: ['w-[130px]', 'h-[195px]', 'min-w-[130px]', 'min-h-[195px]'],
    },
    {
      size: 'md',
      orientation: 'portrait',
      class: ['w-[180px]', 'h-[270px]', 'min-w-[180px]', 'min-h-[270px]'],
    },
    {
      size: 'lg',
      orientation: 'portrait',
      class: ['w-72', 'h-[432px]', 'min-w-[288px]', 'min-h-[432px]'],
    },
    {
      size: 'fluid',
      orientation: 'landscape',
      class: ['aspect-[3/2]'],
    },
    {
      size: 'fluid',
      orientation: 'portrait',
      class: ['aspect-[2/3]'],
    },
  ],
  defaultVariants: {
    size: 'md',
    orientation: 'landscape',
  },
});

export interface CardProps
  extends PropsWithChildren,
    VariantProps<typeof card> {
  link: {
    href: string;
    native?: boolean;
  };
  img?: { src?: string; alt?: string };
  badges?: {
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    component?: ReactNode;
  }[];
}

export const Card = ({
  link,
  img = {},
  badges,
  size,
  orientation,
  children,
}: CardProps) => {
  const { src, alt } = img;
  return (
    <div className={card({ size, orientation })}>
      <Link href={link.href} nativeLink={link.native}>
        <AfishaUzCard>
          <CardImage>
            <ImageWithFallback
              src={src}
              alt={alt}
              className={cx(
                image({ size, orientation }),
                'md:hover:origin-center md:hover:scale-105 md:transition-all md:hover:opacity-90',
              )}
            />
            {badges
              ?.filter(badge => !!badge.component)
              .map((badge, i) => (
                <CardBadge key={i} position={badge.position || 'bottom-right'}>
                  {badge.component}
                </CardBadge>
              ))}
          </CardImage>
          {children}
        </AfishaUzCard>
      </Link>
    </div>
  );
};
